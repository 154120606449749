import React from 'react'
import {graphql} from 'gatsby'
import Layout from '../components/Layout'
import Gallery from '../components/Gallery'
export default ({data}) => {

    return (
        <Layout>
        </Layout>
    )
}

